import React from "react"
import TermsBusiness from "../../../../components/body/pages/en-ng/legal/terms/termsBusiness"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const TermsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/terms/business/"}
      title="Terms & Conditions Business | Kuda | The Money App for Africans"
      description="Kuda is a london based mobile bank offering Zero card maintenance fees, free transfers, automatic savings."
    />
    <TermsBusiness />
  </Layout>
)

export default TermsPage
